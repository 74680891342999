@keyframes moveX{
  // from{background-position-x:0px;}
  // to{background-position-x:-25536px;}
  // from{background-position-x:0%;}
  // to{background-position-x:-95.8333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333%;}
  from{background-position-x:0%;}
  to{background-position-x:-95%;}
}
// @keyframes moveX{
//   // from{left:0px;}
//   // to{left: -25536px;}
//   from{left: 0%;}
//   to{left: -95%;}
// }

#flare {
  // animation: moveX 2s steps(20);
  // width: 100%;
  height: 100%;
  // width: 1280px;
  // height: 720px;
  transform: translate(-50%, -0%);
  left: 50%;
  opacity: 0;
  z-index: -10;

  height: 100%;
  position: absolute;
  img {
    height: 100%;
  }
}
.flare-cont {
  width: 100%;
  .flare-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.flare-animated {
  animation: moveX 2s steps(20) infinite;
  opacity: 1 !important;
  z-index: 1 !important;
}

/* Header */
.header {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  z-index: 101;

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    flex-basis: calc(100% / 3);
  }

  &__logo {
    height: auto;
    display: flex;
    align-items: flex-start;
    position: relative;

    &_big {
      width: 220px;
      height: auto;
    }

    &_small {
      display: block;
      width: 140px;
      height: auto;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
  }

  &__menu {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 6px;
    border-radius: 40px;

    a {
      color: #000;
      width: 180px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.75);
      transition: 0.3s linear;
      margin-right: 2px;

      &:first-child {
        border-radius: 40px 0 0 40px;
      }

      &:last-child {
        border-radius: 0 40px 40px 0;
        margin-right: 0;
      }

      span {
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1;
      }

      p {
        display: none;
        color: #d72229;
        font-size: 18px;
        text-transform: uppercase;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }

    .active {
      background-color: rgba(255, 255, 255, 1);
      cursor: default;
    }

    .demo {
      cursor: default;

      &:hover {
        span {
          display: none;
        }
        p {
          display: block;
        }
      }
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
  }
}

/* Links */
.hLinks {
  display: flex;
  align-items: center;

  &__item {
    position: relative;
    margin-right: 10px;

    &:nth-child(2) .hLinks__item_info {
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) .hLinks__item_info {
      left: auto;
      right: -2px;
    }

    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;

      div {
        width: 100%;
        height: 100%;
      }
      ellipse {
        opacity: 0.5;
        transition: 0.3s linear;
      }

      &:hover {
        ellipse {
          opacity: 0.75;
        }
        ~ .hLinks__item_info {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_last {
      margin-right: 0;
    }

    &_info {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 100%;
      margin-top: 2px;
      left: 0;
      opacity: 0;
      visibility: hidden;
      top: 100%;
      width: 128px;
      transition: 0.2s linear;
    }
  }
}

/* Hamburger */
.hamburger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.3s linear;
  display: none;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.75);
  }

  span {
    display: block;
    width: 18px;
    height: 2px;
    border-radius: 40px;
    margin: 2px 0;
    background-color: #ffe500;
  }

  &.active {
    background-color: rgba(0, 0, 0, 1);
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        width: 23px;
        margin: 0;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
        width: 23px;
        margin: 0;
        margin-top: -2px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .header {
    &__center {
      display: none;
    }

    &__logo_small {
      width: 100px;
    }

    &__logo_big {
      width: 140px;
      top: -5px;
    }
  }

  .hLinks {
    &__item {
      &_icon {
        width: 36px;
        height: 36px;
        img {
          width: 18px;
        }
      }

      // &_info {
      //   display: none !important;
      // }
    }
  }
}
