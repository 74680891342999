/* Footer */
.sFooter {
  position: relative;

  .sBlock__item_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sectionBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .wrap {
    position: relative;
    z-index: 10;
    margin-bottom: 40px;
    margin-right: -10px;
    width: 100%;
    max-width: 1070px;
  }

  &__col {
    flex-basis: calc(100% / 2 - 10px);
    margin-right: 10px;
  }

  &__row {
    display: flex;
    height: 100%;
    margin-right: -10px;
  }

  &__block {
    border-radius: 10px;
    background: rgba(210, 210, 210, 0.75);
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: 0.3s linear;

    &_title {
      font-size: 21px;
      color: #000;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 20px;
      text-align: center;
      font-family: "Copperplate";
    }

    &:hover {
      background: rgba(210, 210, 210, 1);
    }

    &_zip {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin-top: 5px;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 500;

      p {
        background-color: #fff;
        border-radius: 4px;
        margin: 0 5px;
        text-transform: uppercase;
        padding: 2px 15px;
      }
    }
  }

  &__item {
    flex-basis: calc(100% / 2 - 10px);
    margin-right: 10px;
  }

  &__menu {
    display: flex;
    flex-direction: column;

    a {
      font-size: 17px;
      display: block;
      color: rgba(0, 0, 0, 0.75);
      margin-bottom: 15px;
      transition: 0.25s linear;
      width: fit-content;
      text-align: left;

      &:hover {
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .storeLocation {
    margin-bottom: 10px;
    img {
      margin: 0 auto;
    }
  }

  &__top_pattern {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 1;
    opacity: 0;
    transition: 0.5s linear;
  }

  &__bottom_pattern {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 1;
    opacity: 0;
    transition: 0.5s linear;
  }
}

.socials {
  z-index: 10;
  display: flex;
  align-items: center;
  margin-bottom: -84px;

  &__item {
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    margin: 0 15px;
    border: 2px solid #ffe500;
    transition: 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #ffe500;
    }

    div {
      height: auto;
      display: flex;
    }

    svg {
      fill: #ffe500;
      transition: 0.3s linear;
    }

    &:hover svg {
      fill: #000;
    }
  }
}

.mapLeaf {
  display: flex;
  align-items: center;

  span {
    color: #ed1c31;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 5px;
    font-family: "Copperplate";
  }
}

.sFooter-wrapper{
  display: flex;
  justify-content: center;
}

/* MEDIA */
@media (min-width: 1921px) {
  .sFooter .wrap {
    max-width: 1440px;
  }
}

@media (max-width: 1619px) {
  .mapLeaf img {
    max-width: 70px;
  }

  .mapLeaf span {
    font-size: 14px;
  }

  .sFooter__block_zip {
    font-size: 14px;
  }

  .storeLocation img {
    max-width: 200px;
  }

  .sFooter .wrap{
    max-width: 930px;
  }
}

@media (max-width: 1119px) {
  .socials {
    margin-bottom: 0;
  }
}

@media (max-width: 991px) {
  .sFooter {
    padding-top: 40px;
  }

  .sFooter .wrap {
    
    height: 100%;
    overflow-y: scroll;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .sFooter-wrapper{
    flex-direction: column;
  }

  .sFooter .sectionBlock {
    padding: 60px 15px 45px;
  }

  .sFooter__row {
    flex-direction: column;
  }

  .sFooter__item {
    margin-bottom: 10px;
  }

  .sFooter__menu a {
    margin-bottom: 15px;
  }

  .storeLocation img {
    max-width: 240px;
  }
}
