// Layout
@font-face {
  font-family: "Copperplate";
  src: url("");
  src: url("") format("embedded-opentype"),
    url("/fonts/copperplate.woff2") format("woff2"),
    url("/fonts/copperplate.woff") format("woff"),
    url("/fonts/copperplate.woff2") format("truetype"), url("") format("svg");
}

@font-face {
  font-family: "Arial";
  src: url("");
  src: url("") format("embedded-opentype"),
    url("/fonts/ArialMT.woff2") format("woff2"),
    url("/fonts/ArialMT.woff") format("woff"),
    url("/fonts/ArialMT.woff2") format("truetype"), url("") format("svg");
  font-weight: 400;
}

@font-face {
  font-family: "Arial";
  src: url("");
  src: url("") format("embedded-opentype"),
    url("/fonts/Arial-BoldMT.woff2") format("woff2"),
    url("/fonts/Arial-BoldMT.woff") format("woff"),
    url("/fonts/Arial-BoldMT.woff2") format("truetype"), url("") format("svg");
  font-weight: 700;
}

html {
  font-size: 16px;
}

body {
  background: #fff;
  line-height: 1;
  letter-spacing: 0.3px;
  font-family: "Arial", sans-serif;
  min-width: 375px;
  position: relative;
  color: #383c46;
}

body.body-hidden {
  overflow: hidden;
}

.section {
  display: flex;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

/* ================== General =================== */
.main {
  width: 100%;
  height: auto;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: calc(100% - 160px);
  margin: 0 auto;
  height: 100%;
  position: relative;
}

section {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 566px;
}

/* Scrollbar */
.scr_ver {
  overflow-y: auto;
  scrollbar-color: dark;
}

.scr_ver::-webkit-scrollbar {
  width: 7px;
  border-radius: 40px;
}

.scr_ver::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}

.scr_ver::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  cursor: pointer;
}

.scr_ver::-webkit-scrollbar-thumb:hover {
  background: #000;
  cursor: pointer;
}

.blockHeight {
  height: auto;
  max-height: calc(100% - 140px);
}

/* ================== Text =================== */
p {
  display: block;
  font-size: 14px;
  line-height: 1.44;
}

ul li:last-child {
  margin-bottom: 0px;
}

// MEDIA
@media (min-width: 1921px) {
  .blockHeight {
    max-height: calc(100% - 160px);
  }
}

@media (max-width: 1619px) {
  .container {
    max-width: calc(100% - 100px);
  }
  .blockHeight {
    max-height: calc(100% - 118px);
  }
}

@media (max-width: 1300px) {
  .container {
    max-width: calc(100% - 60px);
  }
}

@media (max-width: 1199px) {
  .container {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .scr_ver::-webkit-scrollbar {
    width: 4px;
  }
  .blockHeight {
    max-height: calc(100% - 84px);
  }
}
