/* Related Products */
.rProducts {
  position: relative;

  &__list {
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    align-items: stretch;
    background-color: #fff;
    height: 100%;

    &_title {
      display: block;
      margin-bottom: 15px;
      font-size: clamp(20px, 1.45vw, 44px);
      text-align: center;
      font-weight: 600;
    }

    &_subtitle {
      display: block;
      text-align: center;
      margin-bottom: 15px;
      font-size: clamp(16px, 1.1vw, 36px);
      font-weight: 500;
    }

    &_desc {
      margin-bottom: 20px;
      text-align: center;
      line-height: 1.6;
      font-size: 16px;
      font-size: clamp(15px, 0.8vw, 32px);
      max-width: 85%;
    }

    &_img {
      display: flex;
      align-items: center;
      position: relative;
      display: block;
      width: 100%;
      max-width: clamp(160px, 13.3vw, 400px);
    }

    &_content {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 0 10px;
      max-width: clamp(400px, 19vw, 600px);
      margin: 0 auto;
      position: relative;

      &_text {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: #002c4d;
    width: 54px;
    height: 54px;
    border-radius: 100%;
    border: 3px solid #fff;
    transition: 0.3s linear;
    z-index: 10;
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }

  &-prev {
    left: -25px;
    svg {
      transform: rotate(90deg);
      left: -2px;
      position: relative;
    }
  }

  &-next {
    right: -25px;
    svg {
      transform: rotate(-90deg);
      right: -2px;
      position: relative;
    }
  }

  &-arrow svg path {
    stroke: #fff;
  }
}

.rProductsBlock {
  width: 100%;
  max-width: clamp(640px, 39vw, 1200px);
  border-radius: 23px;
  box-shadow: 1px 9.9px 49.5px 0.5px rgba(0, 0, 0, 0.25);
  background: #fff;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding: 10px;

  &__wrrapper {
    height: 100%;
  }

  &__wrap {
    position: relative;
    overflow-y: auto;
    padding: 25px 25px 20px;
    height: 100%;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-pagination {
    margin-top: -5px;
  }

  .swiper-pagination-bullet-active {
    background-color: #d82f55;
  }

  &.active {
    .swiper-pagination-bullet-active {
      background-color: #002c4d;
    }
    .rProducts-arrow {
      background-color: #d82f55;
    }
  }
}

#relatedProducts .swiper-slide {
  height: auto;
}

.rProducts__btn {
  height: 54px;
  padding: 0 40px;
  background: rgba(216, 47, 85, 0.75);
  border-radius: 25px;
  font-family: "Copperplate";
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
  transition: 0.3s linear;
}

.rProducts__btn:hover {
  background: rgba(216, 47, 85, 1);
}

.rProductsBlock.active .rProducts__btn {
  background-color: rgba(0, 44, 77, 0.75);
}

.rProductsBlock.active .rProducts__btn:hover {
  background-color: rgba(0, 44, 77, 1);
}

.rProducts-mobile {
  display: none;
}

// MEDIA
@media (max-height: 720px) {
  .rProductsBlock {
    height: 100%;

    .swiper-pagination {
      margin-top: 5px;
      padding: 10px 0;
      bottom: 0;
    }

    &__wrrapper {
      position: relative;
      overflow: hidden;
      height: calc(100% - 30px);
    }
  }
}

@media (max-width: 767px) {
  .rProducts {
    &__item {
      flex-direction: column;
      align-items: center;

      &_img {
        width: auto;
        max-width: 300px;
        height: 224px;
      }

      &_title {
        margin-bottom: 5px;

        &.rProducts-mobile {
          display: block;
          font-size: 18px;
        }
      }

      &_subtitle {
        &.rProducts-mobile {
          display: block;
          font-size: 15px;
        }
      }

      &_desc {
        line-height: 1.4;
      }
    }
  }

  .rProductsBlock {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;

    &__wrap {
      padding: 10px 5px;
      height: 100%;
    }
  }

  .rProducts__item_content .rProducts__item_title {
    display: none;
  }

  .rProducts__item_content .rProducts__item_subtitle {
    display: none;
  }

  .rProducts__item_img {
    margin-bottom: 10px;
  }

  .rProducts-arrow {
    top: 40%;
    width: 44px;
    height: 44px;
  }

  .rProducts-prev svg {
    transform: rotate(90deg) scale(0.8);
  }

  .rProducts-next {
    right: -19px;
  }

  .rProducts-prev {
    left: -19px;
  }

  .rProducts .sectionBlock {
    padding: 25px 20px;
  }

  .rProducts__btn {
    font-size: 14px;
    width: 100%;
    padding: 0 15px;
  }

  .rProductsBlock .swiper-pagination {
    margin-top: 5px;
    padding: 10px 0;
    bottom: 0;
  }

  .rProductsBlock__wrrapper {
    position: relative;
    overflow: hidden;
    height: calc(100% - 40px);
    padding: 10px 3px 0;
  }
}



