.preloader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: gray;
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/image/main-bg.jpg');
    background-position: center;
    background-size: cover;
    filter: blur(16px);
    transform: scale(1.1);
  }
  .preloader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 21%;
      min-width: 200px;
    .progress_bar-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 85px;
      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .button-wrapper {
      transition: opacity 0.2s;
      width: 100%;
      height: 36px;
      position: relative;
      opacity: 0;
      button {
        cursor: pointer;
        box-sizing: border-box;
        pointer-events: auto;
        color: white;
        width: 100%;
        height: 50px;
        text-transform: uppercase;
        transition: background-color 200ms, color 200ms, opacity 200ms;
        background-color: black;
        opacity: 0.6;
        &:hover {
          opacity: 1;
          color: yellow;
        }
      }
    }
    .active {
      opacity: 1;
    }
  } 
}