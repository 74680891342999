.menu {
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  left: 10px;
  z-index: 100;
  height: calc(100% - 20px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 101;
  padding: 2px 6px;
  border-radius: 10px;

  &__text {
    display: flex;
    justify-content: center;
    width: 0px;
    overflow: hidden;
    transition: 2s linear;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  li {
    display: flex;
    align-items: center;
    flex-basis: calc(100% / 5);
    padding: 2px 0;
    width: 100%;
    cursor: pointer;
    opacity: 0.75;
    transition: 0.3s linear;

    span {
      font-size: 15px;
      text-transform: uppercase;
      text-align: center;
      display: block;
      position: relative;
      margin: 0 auto;
      padding: 0 5px;
      color: #fff;
    }

    .line {
      height: 100%;
      min-width: 7px;
      max-width: 7px;
      border-radius: 40px;
      background-color: #fff;
      transition: 0.3s linear;
    }

    &.active {
      opacity: 1;
      .line {
        background-color: #ffeb1b;
      }
      span {
        color: #ffeb1b;
      }

      &:hover {
        opacity: 1;
        span {
          color: #ffeb1b;
        }
        .line {
          background-color: #ffeb1b;
        }
      }
    }

    &:hover {
      opacity: 1;
      span {
        color: rgba(255, 255, 255, 1);
      }
      .line {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }

  &:hover {
    li span {
      display: block;
    }
  }
  
}

/* MEDIA */
@media (min-width: 1921px) {
  .menu li span {
    font-size: 18px;
  }
}

@media (max-width: 1619px) {
  .menu {
    width: 118px;
    li span {
      font-size: 15px;
    }
  }
}

@media (max-width: 1199px) {
  .menu {
    height: auto;
    width: calc(100% - 10px) !important;
    top: auto;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 2px;
    border-radius: 6px;

    &__wrap {
      flex-direction: row;
      align-items: flex-end;
    }

    li {
      padding: 2px;
      flex-direction: column;

      span {
        width: auto;
        font-size: 11px;
        order: -1;
        margin-bottom: 3px;
        padding: 0;
      }

      .line {
        min-width: 100%;
        max-width: 100%;
        height: 4px;
      }
    }
  }
}
