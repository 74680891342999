.banner {
  overflow: hidden;
  position: relative;
  z-index: 10;
  // height: 100vh;
  height: 100%;
  background-color: #ccc;

  .sound-bttn {
    position: absolute;
    bottom: 35px;
    right: 0;
    pointer-events: auto;
    padding: inherit;
    div {
      width: 68px;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      transition: 0.3s linear;
      cursor: pointer;
      background: black;
      border-radius: 50px;
      padding: 10px;
    }

    &:hover {
      opacity: 1;
    }
    img {
      width: 100%;
    }
  }

  .header-texture {
    position: absolute;
    width: 100%;
    bottom: -4px;
    img {
      width: 100%;
    }
  }

  .header {
    &__logo_small {
      display: none;
    }
  }

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .topInfo {
    padding: 0 15px;
    opacity: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 100px;
    width: 100%;

    .title {
      font-size: clamp(30px, 3vw, 80px);
      text-transform: uppercase;
      font-family: "Copperplate";
      white-space: normal;
    }
  }

  .fp-tableCell {
    padding: 0;
  }

  .header {
    min-height: 112px;

    &__menu {
      width: 54px;
      overflow: hidden;
      opacity: 0;
      transition: 0.5s linear;
    }

    &__center,
    &__right {
      margin-top: -20px;
    }
  }

  .header__menu a {
    opacity: 0;
    transition: 0.5s linear;
  }

  .header__logo_big {
    opacity: 0;
    transition: 1.5s;
  }

  .hLinks__item_icon {
    opacity: 0;
    transition: 1.5s;
  }

  &.active {
    .header__menu {
      width: 738px;
      opacity: 1;

      a {
        opacity: 1;
        transition-delay: 0.5s;
      }
    }

    .header__logo_big {
      opacity: 1;
    }
    .hLinks__item_icon {
      opacity: 1;
    }
  }
}


// MEDIA
@media (max-width: 767px) {

  .banner .header__center,
  .banner .header__right {
    margin-top: -10px;
  }

}