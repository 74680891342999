.pDetailsBlock {
  width: 100%;
  max-width: clamp(800px, 48vw, 1180px);
  background-color: #fff;
  border-radius: 22px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  box-shadow: 1px 10px 50px 1px rgba(0, 0, 0, 0.25);
  padding: 10px;

  &__content {
    overflow-y: auto;
    padding: 35px 45px 35px 35px;
    max-height: 100%;
  }

  &__title {
    display: block;
    font-size: clamp(14px, 0.8vw, 24px);
    font-weight: 700;
    text-align: center;
  }

  &__subtitle {
    font-size: clamp(14px, 0.8vw, 24px);
    display: block;
    text-align: center;
  }

  &__list {
    display: flex;
    width: 100%;
    margin: 30px 0;

    &_col {
      flex-basis: calc(100% / 3);
      display: flex;
      justify-content: center;
    }

    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &_img {
      width: 210px;
      height: 120px;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      border-radius: 50px;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &_title {
      font-size: 16px;
      color: #383c46;
      display: block;
      font-weight: 700;
    }

    strong {
      font-weight: 700;
    }

    .swiper-pagination-bullet-active {
      background-color: #383c46;
    }
  }

  &__desc {
    background-color: #f0f0f0;
    padding: 20px 15px;
    border-radius: 10px;

    p {
      display: block;
      margin-bottom: 20px;
      text-align: center;
      font-size: clamp(14px, 0.8vw, 24px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


// MEIDA
@media (max-width: 1199px) {
  .pDetailsBlock__content {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .pDetailsBlock {
    padding: 10px 3px;
    border-radius: 12px;
  }

  .pDetailsBlock__content {
    padding: 10px 15px;
  }

  .pDetailsBlock__list {
    margin: 30px 0 15px;
  }

  .swiper-pagination-r {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
}
