* {
    box-sizing: border-box;
    font-weight: normal;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-text-size-adjust: none;
  }
  
  .App {
    overflow: hidden;
  }
  
  body {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    display: block;
  }
  
  ul {
    margin: 0;
    padding: 0;
  }
  
  ul li {
    list-style: none;
  }
  
  input,
  textarea {
    padding: 0;
    margin: 0;
    outline: none;
    line-height: 1;
  }
  
  input:focus,
  input:active {
    outline: none;
    outline: 0;
    outline-offset: 0;
  }
  
  button {
    border: none;
    background: transparent;
    text-decoration: none;
    line-height: 1;
  }
  
  button:hover {
    border: none;
  }
  
  button:focus {
    outline: none;
  }
  
  a {
    text-decoration: none;
    line-height: 1;
  }
  
  p {
    padding: 0;
    margin: 0;
  }
  
  html:where(img) {
    max-height: none;
  }
  
  strong {
    font-weight: 600;
  }