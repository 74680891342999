.howToUse {
  position: relative;

  &__wrap {
    display: flex;
    justify-content: center;
  }

  &__item {
    width: clamp(240px, 15.6vw, 400px);
    height: clamp(240px, 15.6vw, 400px);
    border-radius: 100%;
    position: relative;
    border: 5px solid #ffe500;
    margin: 0 30px;
    cursor: pointer;

    &-advice {
      position: absolute;
      opacity: 0;
      transition: 0.3s linear;
      padding: 0 15px;
    }

    &_img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &_icon {
      position: absolute;
      top: -22px;
      left: 50%;
      transform: translateX(-50%);
      background: #fff;
      width: 30px;
      height: 45px;
      border-radius: 4px;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s linear;

      img {
        display: block;
        width: 16px;
        height: auto;
      }
    }

    &_text {
      position: absolute;
      background-color: #fff;
      padding: 10px 15px;
      border-radius: 10px;
      bottom: -30px;
      width: 100%;
      min-height: 86px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s linear;

      p {
        font-size: clamp(14px, 0.84vw, 20px);
        color: #000;
        text-align: center;
        line-height: 1.3;
      }

      &:after {
        content: "";
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: -19px;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #fff;
        transition: 0.3s linear;
      }
    }

    &:hover {
      .howToUse__item_text {
        background-color: #ffe500;
      }
      .funnel {
        opacity: 1;
      }
      .howToUse__item_icon {
        background-color: #ffe500;
      }
      .howToUse__item_text::after {
        border-bottom: 20px solid #ffe500;
      }
      .howToUse__item-desc {
        display: none;
      }
      .howToUse__item-advice {
        display: block;
        opacity: 1;
      }
    }
  }


}

.howToUseContent {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
}

.funnel {
  position: absolute;
  left: -53px;
  top: -40px;
  z-index: 10;
  width: clamp(180px, 12.6vw, 300px);
  opacity: 0;
  transition: 0.3s linear;
}

/* Tabs */
.tab {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tabNav {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #c0c0c0;
    padding: 6px;
    border-radius: 40px;
    width: fit-content;

    &__item {
      background-color: rgba(255, 255, 255, 0.75);
      transition: 0.3s linear;
      min-width: clamp(240px, 15vw, 360px);
      padding: 10px 0;
      cursor: pointer;

      &.demo {
        cursor: default;

        p {
          display: none;
          text-transform: uppercase;
          text-align: center;
          font-weight: 500;
          color: #383c46;
          font-family: "Copperplate";
          font-size: clamp(14px, 0.84vw, 18px);
          line-height: 1.2;
        }

        &:hover {
          span {
            display: none;
          }
          p {
            display: block;
          }
        }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }

      &.active {
        background-color: #ffe500;
        cursor: default;

        &:hover {
          background-color: #ffe500;
        }
      }

      &:nth-child(1) {
        border-radius: 40px 0 0 40px;
        border-right: 6px solid #c0c0c0;
      }

      &:nth-child(2) {
        border-radius: 0 40px 40px 0;
      }

      span {
        text-transform: uppercase;
        text-align: center;
        display: block;
        font-weight: 500;
        color: #383c46;
        font-family: "Copperplate";
        font-size: clamp(14px, 0.84vw, 18px);
      }
    }
  }
}

.tabContent {
  overflow-y: auto;
  padding-top: 20px;
  margin-top: 30px;
  padding-bottom: 40px;
  height: auto;

  &__item {
    display: none;

    &.active{
      display: block;
    }
  }
}

// MEDIA
@media (max-width: 767px) {
  .howToUse {
    &__wrap {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      margin-bottom: 60px;
    }
  }

  .howToUseContent {
    height: 100% !important;
  }

  // Tabs
  .tab {
    .tabNav {
      flex-direction: column;
      align-items: center;
      &__item {
        min-width: 300px;

        &:nth-child(1) {
          border-radius: 40px 40px 0 0;
          border-right: 0;
        }

        &:nth-child(2) {
          border-radius: 0 0 40px 40px;
        }
      }
    }
  }

  .tabContent {
    padding-bottom: 0;
  }
}
