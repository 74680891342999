/* Content Area */
.contentArea {
  position: relative;
  overflow: hidden;
  height: 100%;

  .menu {
    width: 19px;
    transition: width 0.5s linear;
    overflow: hidden;

    li {
      span {
        opacity: 0;
        transition: 0.5s;
      }
    }
  }

  .header {
    position: relative !important;
    padding: 15px 0;

    &__logo_big {
      display: none;
    }
    .container {
      max-width: 100%;
      padding: 0;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .header {
    &__logo_small {
      opacity: 0;
      transition: 1s;
    }

    &__menu {
      width: 54px;
      overflow: hidden;
      opacity: 0;
      transition-delay: 0.5s;
      transition: 0.5s linear;
      a {
        opacity: 0;
        transition-delay: 0.5s;
        transition: 0.5s linear;
      }
    }
  }

  .hLinks__item_icon {
    opacity: 0;
    transition: 1s;
  }

  .hamburger {
    display: flex;
    opacity: 0;
    transition: 1s;
  }

  &.elementAnimate {
    .menu {
      width: 137px;

      li {
        span {
          opacity: 1;
          position: relative;
          transition-delay: 0.5s;
          transition-property: opacity;
          // transition: color 0.3s linear;
        }
      }
    }

    .header__logo_small {
      opacity: 1;
    }

    .hLinks__item_icon,
    .hamburger {
      opacity: 1;
    }

    .header__menu {
      width: 738px;
      opacity: 1;

      a {
        opacity: 1;
        transition-delay: 0.5s;
      }
    }
  }
}

.sBlock__item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  opacity: 0;
  z-index: -1;

  .blockForTabAnimate {
    opacity: 0;
    transition: 0.5s linear;
  }

  &.active {
    opacity: 1;
    z-index: 2;

    .sectionBGI,
    .sFooter__top_pattern,
    .sFooter__bottom_pattern {
      opacity: 1;
      transition-delay: 0.5s;
    }

    .blockForTabAnimate {
      opacity: 1;
      transition-delay: 0.8s;
    }
  }

  &_wrapper {
    width: 100%;
    height: 100%;
  }

  &_wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 45px 45px 20px 45px;
  }
}

.sectionBlock {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;

  .sBlock__item {
    opacity: 0;
    transition: 1s linear;
  }

  .sBlock__item_wrapper {
    opacity: 0;
    transition: 1s linear;
  }

  &.blockAnimate {
    .sBlock__item {
      opacity: 1;
    }
    .sBlock__item_wrapper {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }
}

.sectionBGI {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: inherit;
  opacity: 0;
  transition: 0.5s linear;
}

.fp-overflow {
  height: 100%;
}

.fp-warning,
.fp-watermark a {
  display: none !important;
}

/* Top Title & Subtitle */
.topInfo {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
    line-height: 1.1;
    font-weight: 700;
    font-size: 54px;
    text-transform: uppercase;
    color: #383c46;
    font-family: "Copperplate";
    text-align: center;
  }

  .subtitle {
    color: #383c46;
    font-size: 24px;
    text-transform: uppercase;
    font-family: "Copperplate";
    text-align: center;
  }
}

/* Footer */
.footer {
  width: 100%;
  padding: 15px 0;

  .wrap {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__menu {
    display: flex;
    align-items: center;

    a {
      color: rgba(0, 0, 0, 0.75);
      transition: 0.3s linear;

      &:hover {
        color: rgba(0, 0, 0, 1);
      }
    }

    &_line {
      display: block;
      height: 18px;
      width: 1px;
      background: rgba(0, 0, 0, 0.75);
      margin: 0 10px;
    }
  }
}

/* Modal */
.contentArea__modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  z-index: 112;
  padding: 10px;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &_scroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 30px;
  }

  &_wrap {
    width: 100%;
    max-width: 820px;
    margin: 0 auto;
  }

  h2 {
    position: relative;
    text-align: center;
    color: #383c46;
    font-size: clamp(30px, 3vw, 80px);
    text-transform: uppercase;
    font-family: "Copperplate";
  }

  &_subtitle {
    position: relative;
    text-align: center;
    display: block;
    font-size: clamp(20px, 1.5vw, 40px);
    text-transform: uppercase;
    font-family: "Copperplate";
    margin-bottom: 60px;
  }

  p {
    position: relative;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    display: block;
    margin-bottom: 20px;
  }
}

.modal-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

// MEDIA
@media (min-width: 1921px) {
  .sBlock__item_wrap {
    padding: 65px;
  }

  .topInfo {
    .title {
      font-size: 66px;
    }

    .subtitle {
      font-size: 32px;
    }
  }
}

@media (max-width: 1619px) {
  .topInfo {
    .title {
      font-size: 40px;
    }

    .subtitle {
      font-size: 20px;
    }
  }

  .sectionBlock__item {
    padding: 25px;
  }

  .footer {
    &__menu {
      a {
        font-size: 14px;
      }
      &_line {
        height: 14px;
      }
    }
  }

  .header__contentArea {
    position: relative;
    padding: 10px 0;
  }
}

@media (max-width: 1619px) {

  .contentArea.elementAnimate .menu{
    width: 118px;
  }
  
}

@media (max-width: 1199px) {
  .sectionBlock {
    border-radius: 10px;

    &__item {
      padding: 25px 15px 45px;
    }
  }

  .sBlock__item_wrap {
    padding: 20px 20px 70px;
  }

  /* Footer */
  .footer {
    padding: 6px 0;

    &__right {
      display: none;
    }

    .wrap {
      justify-content: center;
    }
  }

  .copyright {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .sectionBlock {
    border-radius: 12px;
  }

  .sectionBGI {
    border-radius: inherit;
  }
  

  .topInfo {
    margin-bottom: 20px;
    margin-top: 57px !important;

    .title {
      font-size: 26px;
      white-space: nowrap;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 17px;
    }
  }

  .contentArea__modal {
    padding: 10px 3px;

    &_subtitle {
      margin-bottom: 30px;
    }

    &_scroll {
      padding: 15px;
    }
  }
}
