.aSizes {
  position: relative;
}

.aSizesTab {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aSizesTabNav {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 10;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;

  &__item {
    display: flex;
    align-items: flex-end;
    position: relative;
    width: auto;
    margin: 0 16px;
    cursor: pointer;

    .st0AS {
      transition: 0.3s linear;
    }

    &.active {
      .st0AS {
        opacity: 1;
      }
      .st1 {
        fill: #ffe500;
      }

      &:hover {
        .st0AS {
          opacity: 1;
        }
      }
    }

    &:nth-child(1) {
      svg {
        height: clamp(62px, 4.2vw, 100px);
      }
    }

    &:nth-child(2) {
      svg {
        height: clamp(66px, 4.5vw, 106px);
      }
    }

    &:nth-child(3) {
      svg {
        height: clamp(72px, 5vw, 112px);
      }
    }

    &:nth-child(4) {
      svg {
        height: clamp(79px, 5.5vw, 118px);
      }
    }

    &:hover {
      .st0AS {
        opacity: 0.75;
      }
    }
  }
}

.aSizes__info {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  z-index: 10;
  background-color: #ffe500;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 40px;
}

.aSizes__info_item.active {
  display: block;
}

.aSizes__info_wrap {
  display: flex;
  align-items: center;
}

.aSizes__info_item {
  position: relative;
  display: none;
  font-size: clamp(14px, 0.9vw, 26px);
}

.aSizes__info_item img {
  display: block;
  margin: 0 6px;
  position: relative;
  top: -2px;
}

.aSizesTabContent {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.aSizesTabContent img {
  display: none;
  width: auto;
  height: 340px;
}

.aSizesTabContent .active {
  display: block;
}

.grass {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: auto;
}

.mobile-grass {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 10;
  width: 100%;
  min-width: 580px;
  height: auto;
  display: none !important;
}


// MEDIA
@media (min-width: 1921px) {
  .aSizesTabContent img {
    height: 450px;
  }
}

@media (max-width: 1619px) {
  .aSizes__info_item {
    font-size: 16px;
  }

  .aSizesTabNav {
    margin-bottom: 10px;
  }

  .aSizesTabContent img {
    max-height: 240px;
  }
}

@media (max-width: 767px) {
  .grass {
    display: none;
  }

  .mobile-grass {
    display: block !important;
  }
  .aSizesTabNav__item {
    margin: 0 5px;
  }

  .aSizes__info_wrap {
    display: block;
    text-align: center;
    font-size: 14px;
  }

  .aSizes__info_item img {
    display: none;
  }

  .aSizesTabContent {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .aSizesTabContent {
    bottom: 70px;
  }

  .aSizesTabContent__item {
    &:nth-child(1) {
      max-height: 200px;
    }
    &:nth-child(2) {
      max-height: 220px;
    }
    &:nth-child(3) {
      max-height: 240px;
    }
    &:nth-child(4) {
      max-height: 260px;
    }
  }
}
